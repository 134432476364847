import { getCookie } from "utils/common";
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { postRequest } from "utils/requests";

export const useFBConversion = () => {
  const userState = useSelector(selectUser);

  const fbp = getCookie("_fbp");
  const fbc = getCookie("_fbc");
  const REQ_BODY = {
    email: userState?.user_info?.email,
    external_id: userState?.user_info?.external_id,
    name: userState?.user_info?.name,
    b_year: userState?.user_info?.b_year,
    b_month: userState?.user_info?.b_month,
    b_day: userState?.user_info?.b_day,
    fbp: fbp,
    fbc: fbc,
  };

  const sentFBAuth = () => {
    if (userState?.isFBConversion) {
      postRequest("marketing/auth_conversion", REQ_BODY);
    }
  };

  const sentFBAuth55 = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/auth_conversion_55", REQ_BODY);
    }
  };

  const sentFBDelfinConversion = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/delfin_conversion", REQ_BODY);
    }
  };

  const sentFBConfirmEmailConversion = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/confirm_email_conversion", REQ_BODY);
    }
  };

  const sentFBFirstPurchase = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/payment_conversion", REQ_BODY);
    }
  };

  const sentFBTwoMorePurchase = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/two_more_purchase_conversion", REQ_BODY);
    }
  };

  const sentFBSpendAllCredits = () => {
    if (userState?.isFBConversion) {
      postRequest("/api/marketing/spend_all_credits_conversion", REQ_BODY);
    }
  };

  return {
    sentFBAuth,
    sentFBAuth55,
    sentFBDelfinConversion,
    sentFBConfirmEmailConversion,
    sentFBFirstPurchase,
    sentFBTwoMorePurchase,
    sentFBSpendAllCredits,
  };
};
