import { Fragment } from "react";
import { Avatar, Button, SliderContent } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

import { formatDateTime, objectToArray } from "utils/common";

import cn from "classnames";

// @ts-ignore
import classes from "./MailTextBox.module.scss";

interface IProps {
  blure?: boolean;
  name: string;
  user_name: string;
  age: any;
  data: any;
  onChangeSetbookmark: any;
  onChangeTrashmail: any;
  handleContent?: any;
  handlePay?: any;
  freeRead?: boolean;
  price:
    | {
        [key: string]: number;
      }
    | undefined;
}

const MailTextBox = ({
  blure,
  name,
  user_name,
  age,
  data,
  onChangeSetbookmark,
  onChangeTrashmail,
  handleContent,
  handlePay,
  price,
}: IProps) => {
  const {
    read_status,
    sender_image,
    message_content,
    date_created,
    bookmark,
    id,
    male_id,
    sender_id,
    chat_id,
  } = data.mail;

  const isUserCard = male_id === sender_id;

  const splitAndBlur = (text: string) => {
    const maxLength = 25;
    const lines = text.split("\n");
    const firstPart = lines.map((line) => line.slice(0, maxLength)).join("\n");
    const restPart = lines.map((line) => line.slice(maxLength)).join("\n");

    return (
      <Fragment>
        {blure ? firstPart : firstPart + restPart}
        {blure && restPart && (
          <Fragment>
            <span className={classes.blur}>{restPart}</span>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <div
      className={cn(classes.wrap, read_status === 0 && classes.wrap_notRead)}
    >
      <div className={classes.header}>
        <div className={classes.avatart}>
          <Avatar
            small
            imgLink={sender_image}
            user_name={isUserCard ? user_name : name}
          />
          <p className={classes.name}>
            {isUserCard ? "You" : `${name}, ${age}`}
          </p>
        </div>
        {!isUserCard && (
          <Button
            text={bookmark === 0 ? "Bookmarks" : "Bookmarked"}
            iconSvg={
              bookmark === 0 ? "c3l-bookmark-empty" : "c3l-bookmark-full"
            }
            onClick={() => {
              onChangeSetbookmark(id, male_id, bookmark);
            }}
            className={classes.btn}
            isWhite
            borderOff
            noBg
          />
        )}
      </div>
      <p className={classes.text}>{splitAndBlur(message_content)}</p>
      {isUserCard && (
        <Image
          name={`${!!read_status ? "readed" : "send"}`}
          className={classes.readStatus}
        />
      )}
      <div className={classes.footer}>
        <p className={classes.time}>{formatDateTime(date_created)}</p>
        <Button
          onClick={() => {
            onChangeTrashmail(id, male_id, isUserCard);
          }}
          isWhite
          noBg
          borderOff
          icon={"been"}
          text="Delete"
          color={"#3333334d"}
          className={classes.del}
        />
      </div>
      {Object.values(data.attachments).length > 0 && (
        <div>
          <div className={classes.line} />
          {!objectToArray(data.attachments)?.every(
            (item: any) => +item?.attachment_free
          ) && (
            <p className={classes.costContentText}>
              {`The cost of each attachment is: photo - ${price?.GET_IMAGE_MAIL}
              credits, video - ${price?.GET_VIDEO_MAIL_NEW} credits, audio -
              ${price?.GET_AUDIO_NEW} credits. Special bonus - the first photo is
              free.`}
            </p>
          )}

          <SliderContent
            handleContent={handleContent}
            girlsList={Object.values(data.attachments)}
            chat_id={chat_id}
          />
        </div>
      )}
      {blure && (
        <button onClick={() => handlePay(id)} className={classes.payBtn}>
          Read for 10 credits
        </button>
      )}
    </div>
  );
};

export default MailTextBox;
