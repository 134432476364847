import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  InputModern,
  Box,
  ModalAlert,
} from "components/atomsKit/Elements";
import { Textonchat, Image } from "components/designAtom/Elements";

import { cutTextforToltip } from "utils/common";
import { postRequest } from "utils/requests";

import { togleGlobalModal } from "redux/interface/slice";
import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./ModalSupport.module.scss";

const ModalSupport = ({
  modalIsOpen,
  closeModal,
  payment_total = 0,
  startMail = undefined,
  placeholderInputMessage = "Please, describe the reason",
}) => {
  const [mail, setMail] = useState(startMail);
  const [name, setName] = useState("");
  const [describe, setDescribe] = useState("");
  const [selectFileToComplaint, setSelectFileToComplaint] = useState();

  const dispatch = useDispatch();

  const onChangeMail = (e) => {
    setMail(e.target.value);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeDescribe = (e) => {
    setDescribe(e.target.value);
  };

  const changeFileToComplaint = (event) => {
    setSelectFileToComplaint(event);
  };

  const sendSupportReq = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("message_content", describe);
    formData.append("files[]", selectFileToComplaint?.target.files[0]);
    formData.append("name", name);
    formData.append("email", mail);
    formData.append(
      "brand_url",
      MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_BRAND_URL
    );
    formData.append("payment_total", payment_total);

    if (describe !== "") {
      postRequest("/api/support/help", formData).then((res) => {
        closeModal();
        setDescribe("");
        setSelectFileToComplaint(undefined);
        const message = () => {
          return (
            <div>
              <p style={{ marginBottom: "10px" }}>{res.message}</p>
              <a
                style={{ fontSize: "20px" }}
                href={`mailto:${
                  MIRROR[process.env.REACT_APP_MIRROR_NAME]
                    .REACT_APP_SUPPORT_MAIL
                }`}
                className={classes.mailLigal}
              >
                {
                  MIRROR[process.env.REACT_APP_MIRROR_NAME]
                    .REACT_APP_SUPPORT_MAIL
                }
              </a>
            </div>
          );
        };
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: message(),
            btnText: "Got it",
          })
        );
      });
    }
  };

  return (
    <ModalAlert
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      icon="x1"
      allertMessage={"Message to support"}
    >
      <form onSubmit={sendSupportReq}>
        <p className={classes.description}>Please, describe your problem</p>
        <InputModern
          type="email"
          placeholder="Your E-Mail"
          value={mail}
          onChange={onChangeMail}
        />
        <InputModern placeholder="Name" value={name} onChange={onChangeName} />

        <InputModern
          isTextArea
          onChange={onChangeDescribe}
          placeholder={placeholderInputMessage}
          value={describe}
          className={classes.messageInput}
        />
        {!selectFileToComplaint ? (
          <label className={classes.uploadBtn}>
            <input
              style={{ display: "none" }}
              type="file"
              id="popup_inp_file1"
              onChange={changeFileToComplaint}
            />
            <Button
              margin="20px 0 0 0"
              iconSvg="support-file-ico"
              text="Choose your file, please"
              isWhite
              className={classes.btnDecor}
              type="button"
            />
          </label>
        ) : (
          <Box marginTop="10px" flex align={"center"} justify={"between"}>
            <Box flex align={"center"}>
              <Image name="file_uploaded" className={classes.imgUpload} />
              <Textonchat
                // @ts-ignore
                text={`File uploaded: ${cutTextforToltip(
                  // @ts-ignore
                  selectFileToComplaint.target.files[0].name,
                  10
                )} (${Math.round(
                  // @ts-ignore
                  selectFileToComplaint.target.files[0].size / 1024
                )} kb)`}
                size={12}
                isSpan
                margin="0 0 0 10px"
                className={classes.text}
              />
            </Box>
            <button onClick={() => setSelectFileToComplaint(undefined)}>
              <Image name="Trash-red" />
            </button>
          </Box>
        )}

        {/* <p className={classes.ligalText}>
          <Timeonchat
            isSpan
            text="If you haven't mentioned your e-mail address during registration, please, contact us at "
          />
          <a
            href={`mailto:${MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_SUPPORT_MAIL}`}
            className={classes.mailLigal}
          >
            {MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_SUPPORT_MAIL}
          </a>
        </p> */}

        <Button
          margin="20px 0 0 0"
          padding="0 20px"
          text={"Send"}
          type="submit"
        />
      </form>
    </ModalAlert>
  );
};

export default ModalSupport;
