import {
  ModalWindowModern,
  Box,
  CloseButton,
  Image,
} from "components/atomsKit/Elements";

// @ts-ignore
import NEW_IMG from "assets/img/new.png";

import cn from "classnames";
import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./ModalWelcome.module.scss";

interface IProps {
  closeModal?: any;
  modalIsOpen?: any;
  balance?: any;
}

const ModalWelcome = ({ closeModal, modalIsOpen, balance }: IProps) => {
  return (
    <>
      <ModalWindowModern
        modalIsOpen={modalIsOpen}
        hideCloseButton
        closeModal={closeModal}
      >
        <div className={classes.wrap}>
          <CloseButton onClick={closeModal} />
          <Image
            name={
              // @ts-ignore
              MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_LOGO_LOGIN
            }
            className={classes.logo}
          />
          <p className={classes.text}>
            Welcome to {/* @ts-ignore  */}
            {MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_NAME}!
          </p>

          <p className={cn(classes.description, classes.description_center)}>
            {/* @ts-ignore */}
            Join {MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_NAME} 's
            hourly success stories and find your soul mate.
          </p>
          <img src={NEW_IMG} alt="" className={classes.img} loading="lazy" />
          {balance > 0 && (
            <p className={classes.freeCredits}>
              You’ve got {balance} free credits to begin with!
            </p>
          )}
          <Box flex align="center" justify={"start"}>
            <p className={classes.tip}>Our tip:</p>
            <p className={classes.description}>
              Users who initiate a conversation on our site are more likely to
              find love quicker
            </p>
          </Box>
          {/* <button className={classes.btnStart}>How to start</button> */}
          <button className={classes.btnFind} onClick={closeModal}>
            Find your match
          </button>
        </div>
      </ModalWindowModern>
    </>
  );
};

export default ModalWelcome;
