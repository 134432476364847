import { useEffect, useState } from "react";
import { postRequest } from "utils/requests";

import { MIRROR } from "data/mirrorConfig";

const useGetZeroBounceConfig = () => {
  const [hasZeroBounce, setHasZeroBounce] = useState(false);

  useEffect(() => {
    postRequest(`/api/v3/click-history/get-lnd-zero-bounce-setting`, {
      lnd: MIRROR[
        process.env.REACT_APP_MIRROR_NAME
      ].REACT_APP_NAME.toLocaleLowerCase(),
      site_id: Number(
        MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_SITE_ID
      ),
    })
      .then((res) => {
        if (res?.status) setHasZeroBounce(res?.status);
      })
      .catch((e) => {
        console.log(e);
        return { status: false };
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return { hasZeroBounce };
};

export default useGetZeroBounceConfig;
