import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  chatCounter,
  fetchUploadFiles,
  fetchVideoShow,
  fetchSayHalloList,
  dataModalContent,
  fetchCheckNotify,
} from "./operations";

import { MIRROR } from "data/mirrorConfig";

const interfaceInitialState = {
  reFetch: { action: {}, triger: false },
  isOpenFilter: false,
  isOpenAlert: false,
  sound: true,
  globalModal: { isOpen: false, text: "", mode: undefined },
  modalContent: { isOpen: false, data: [] },
  chatCount: 0,
  mailBoxCount: 0,
  uploadFiles: { videos: [], photos: [], audios: [] },
  transaction: [],
  notification: [],
  selectedGifts: [],
  videoShow: [],
  giftFor: {},
  sayHalloList: [],
  isLoading: false,
};

const interfaceSlice = createSlice({
  name: "interface",
  initialState: interfaceInitialState,
  reducers: {
    loaderOn(state) {
      state.isLoading = true;
    },
    loaderOff(state) {
      state.isLoading = false;
    },
    soundTogle(state) {
      const old_item = localStorage.getItem("sound");
      localStorage.setItem("sound", old_item === "1" ? "0" : "1");
      state.sound = !state.sound;
    },

    togleReFetch(state, action) {
      if (action.payload) {
        state.reFetch.triger = !state.reFetch.triger;
        state.reFetch.action = action.payload;
      }
    },

    clickFilter(state, action) {
      if (action.payload === "close") {
        state.isOpenFilter = false;
      } else if (action.payload === "open") {
        state.isOpenAlert = false;
        state.isOpenFilter = true;
      } else if (action.payload === "toggle") {
        state.isOpenAlert = false;
        state.isOpenFilter = !state.isOpenFilter;
      }
    },
    clickAlert(state, action) {
      if (action.payload === "close") {
        state.isOpenAlert = false;
      } else if (action.payload === "open") {
        state.isOpenFilter = false;
        state.isOpenAlert = true;
      } else if (action.payload === "toggle") {
        state.isOpenFilter = false;
        state.isOpenAlert = !state.isOpenAlert;
      }
    },

    addGift(state, action) {
      state.selectedGifts = [...state.selectedGifts, action.payload];
    },
    delGift(state, action) {
      state.selectedGifts = state.selectedGifts?.filter(
        (item) => item.id !== action.payload
      );
    },
    clearGifts(state) {
      state.selectedGifts = [];
    },
    changeProfileForGift(state, action) {
      state.giftFor = action.payload;
    },

    togleModalContent(state, action) {
      state.modalContent = {
        isOpen: action.payload,
        data: action.payload ? [...state.modalContent?.data] : [],
      };
    },
    setDataModalContent(state, action) {
      state.modalContent = {
        isOpen: state.modalContent?.isOpen,
        data: action.payload,
      };
    },

    togleGlobalModal(state, action) {
      if (action.payload.isOpen === "close") {
        state.globalModal.isOpen = false;
        state.globalModal.text = "";
        state.globalModal.description = "";
        state.globalModal.onClick = undefined;
        action.payload.btnText = "Got it";
        state.globalModal.mode = undefined;
      } else if (action.payload.isOpen === "open") {
        state.globalModal.isOpen = true;
        state.globalModal.text = action.payload.text;
        state.globalModal.description = action.payload.description;
        state.globalModal.onClick = action.payload.onClick;
        state.globalModal.btnText = action.payload.btnText;
      } else if (action.payload.isOpen === "openPaySucces") {
        state.globalModal.isOpen = true;
        state.globalModal.mode = "paySucces";
        state.globalModal.description = action.payload.description;
        state.globalModal.onClick = action.payload.onClick;
      } else if (action.payload.isOpen === "openPayFail") {
        state.globalModal.isOpen = true;
        state.globalModal.mode = "payFail";
        state.globalModal.onClick = action.payload.onClick;
      }
    },

    addTransaction(state, action) {
      const newTransaction = {
        ...action.payload,
        id:
          moment().unix() +
            action.payload?.notification_object?.sender_external_id ||
          moment().unix(),
      };

      state.transaction = [...state.transaction, newTransaction];
    },

    delTransaction(state, action) {
      state.transaction = state.transaction.filter(
        (item) => item.idTransaction !== action.payload
      );
    },

    addNotification(state, action) {
      state.notification = [...state.notification, action.payload];
    },

    delNotification(state, action) {
      state.notification = state.notification.filter(
        (item) => item.idTransaction !== action.payload
      );
    },
  },
  extraReducers: {
    [chatCounter.fulfilled](state, action) {
      state.chatCount = action.payload.chat;
      state.mailBoxCount = action.payload.mailbox;
    },

    [fetchUploadFiles.pending](state) {
      state.isLoading = false;
    },
    [fetchUploadFiles.fulfilled](state, action) {
      state.uploadFiles = action.payload;
    },

    [fetchVideoShow.fulfilled](state, action) {
      state.videoShow = action.payload;
    },
    [fetchVideoShow.rejected](state) {
      state.isLoading = false;
    },

    [dataModalContent.pending](state) {
      state.isLoading = true;
    },
    [dataModalContent.fulfilled](state, action) {
      state.modalContent = {
        isOpen: state.modalContent?.isOpen,
        data: action.payload,
      };
      state.isLoading = false;
    },
    [dataModalContent.rejected](state) {
      state.isLoading = false;
      state.modalContent = {
        isOpen: false,
        data: [],
      };
    },

    [fetchSayHalloList.pending](state) {
      state.isLoading = true;
    },
    [fetchSayHalloList.fulfilled](state, action) {
      state.sayHalloList = [
        {
          id: 0,
          site_id: MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_SITE_ID,
          message_content: "Send a wink 😉",
        },
        ...action.payload.filter(
          (item) =>
            item.site_id ===
            Number(MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_SITE_ID)
        ),
      ];
      state.isLoading = false;
    },
    [fetchSayHalloList.rejected](state) {
      state.isLoading = false;
    },

    [fetchCheckNotify.fulfilled](state, action) {
      state.notification = [
        ...state.notification,
        ...action.payload.map((item) => ({ notification_object: item })),
      ];
    },
  },
});

export const {
  clickFilter,
  clickAlert,
  togleGlobalModal,
  togleReFetch,
  addGift,
  delGift,
  clearGifts,
  changeProfileForGift,
  togleModalContent,
  setDataModalContent,
  addTransaction,
  delTransaction,
  addNotification,
  delNotification,
  loaderOn,
  loaderOff,
  soundTogle,
} = interfaceSlice.actions;
export const interfaceReducer = interfaceSlice.reducer;
