import { MIRROR } from "data/mirrorConfig";

export const selectStyles = {
  valueContainer: (styles) => ({
    ...styles,
    display: "flex",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_COLOR_LOADER,
    fontWeight: 600,
  }),
  control: (styles, value) => {
    return {
      ...styles,
      minHeight: "48px",
      borderRadius: "5px",
      border: value.selectProps.error ? "1px solid #EB5757" : "none",
      backgroundColor: "#F8F8F8",
      ":hover": {
        borderRadius: "3px",
        borderColor: "none",
        cursor: "pointer",
      },
      ":focus-within": {
        borderColor: "#3e88ff",
        boxShadow: "0 4px 8px 0 rgba(59, 116, 168, 0.2)",
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    marginTop: "5px",
    borderRadius: "3px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#808080",
    fontWeight: 600,
  }),
  option: () => {
    return {
      display: "flex",
      alignItems: "center",
      padding: "15px",
      margin: "0",
      backgroundColor: "#F8F8F8",
      fontSize: "16px",
    };
  },
};
