import { postRequest, getRequest } from "utils/requests";

export const useHandleBilling = () => {
  const createBillingLog = (action, extra = null) => {
    const client = new window.ClientJS();
    const clientData = {
      getCustomFingerprint: client.getCustomFingerprint(
        client.getBrowserData().ua,
        client.getCanvasPrint()
      ),
      getEngine: client.getEngine(),
      getFingerprint: client.getFingerprint(),
      getAvailableResolution: client.getAvailableResolution(),
      getBrowser: client.getBrowser(),
      getBrowserData: client.getBrowserData(),
      getBrowserMajorVersion: client.getBrowserMajorVersion(),
      getCPU: client.getCPU(),
      getSoftwareVersion: client.getSoftwareVersion(),
      getDevice: client.getDevice(),
      getDeviceType: client.getDeviceType(),
      getDeviceVendor: client.getDeviceVendor(),
      getScreenPrint: client.getScreenPrint(),
      getPlugins: client.getPlugins(),
      getTimeZone: client.getTimeZone(),
      getLanguage: client.getLanguage(),
    };

    const logData = {
      deviceID: clientData.getFingerprint,
      device: JSON.stringify(clientData),
      link: window.location.href,
      action,
    };

    if (extra) logData.extra = JSON.stringify(extra);

    return postRequest(`/api/v3/billing/log`, logData);
  };

  const getVatBonus = async () => {
    const res = await getRequest(`/api/v3/billing/vat-bonus`);
    return res?.status;
  };

  return { createBillingLog, getVatBonus };
};
