import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BanerHeaderConfirm,
  BanerHeaderVip,
} from "components/atomsKit/Elements";

import { selectUser } from "redux/auth/selectors";

const BanersSubPage = () => {
  const [isOpenBanerHeaderConfirm, setIsOpenBanerHeaderConfirm] =
    useState(false);
  const [isOpenBanerHeaderVip, setIsOpenBanerHeaderVip] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const userInfo = useSelector(selectUser)?.user_info;

  useEffect(() => {
    setIsOpenBanerHeaderConfirm(userInfo?.email_confirmed === 0);
    setIsOpenBanerHeaderVip(!!userInfo?.convert_user);
    setIsVerified(!!userInfo?.is_verified);
    // eslint-disable-next-line
  }, [userInfo]);

  return (
    <>
      {isOpenBanerHeaderConfirm === true && isOpenBanerHeaderVip === false && (
        <BanerHeaderConfirm
          handleCloseBaner={(event) => {
            event.stopPropagation();
            setIsOpenBanerHeaderConfirm(false);
          }}
        />
      )}
      {isOpenBanerHeaderVip === true && isVerified === false && (
        <BanerHeaderVip
          handleCloseBaner={(event) => {
            event.stopPropagation();
            setIsOpenBanerHeaderVip(false);
          }}
        />
      )}
    </>
  );
};

export default BanersSubPage;
