import { Hearts } from "react-loader-spinner";
import cn from "classnames";

import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./Loader.module.scss";

interface IProps {
  isLoading?: boolean;
  fullScreen?: boolean;
}

const Loader = ({ isLoading, fullScreen }: IProps) => {
  return (
    <>
      {isLoading && (
        <div
          className={cn(classes.wrap, fullScreen && classes.wrap_fullScreen)}
        >
          <Hearts
            height="80"
            width="80"
            color={
              // @ts-ignore
              MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_COLOR_LOADER
            }
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
