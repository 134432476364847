import * as USER from "data/user";
import { diffTimeInDaysWithCurrentDay } from "utils/common";
import { postRequest, getRequest } from "./requests";

export async function sendAfficeFromStore({ info, status }) {
  if (info?.external_id && status && !+info?.ghost) {
    const affiseClickIDRes = await postRequest(
      "/api/v3/click-history/check-affise-click-id",
      { external_id: info?.external_id }
    ).catch(() => null);

    if (affiseClickIDRes?.status) {
      if (
        status === USER.AFFISE_FIRST_PACK &&
        +info?.age < 45 &&
        +info?.age >= 30
      ) {
        getRequest(
          `v3/click-history/affise-call?click_id=${affiseClickIDRes?.status}&goal=${USER.AFFISE_FIRST_PACK_SALE_LESS_THEN_45}&external_id=${info?.external_id}`
        ).catch(() => null);
      } else if (+info?.age >= USER.TRUE_USER_AGE) {
        getRequest(
          `v3/click-history/affise-call?click_id=${affiseClickIDRes?.status}&goal=${status}&external_id=${info?.external_id}`
        ).catch(() => null);
      }
    }
  }
}

export function diffAfficeByRegisterDate(userInfo) {
  const diffDay = JSON.parse(
    localStorage.getItem(`rd-${userInfo?.external_id}`)
  );
  const is2Day =
    JSON.parse(localStorage.getItem(`rdaf-2-${userInfo?.external_id}`)) ?? null;
  const is6Day =
    JSON.parse(localStorage.getItem(`rdaf-6-${userInfo?.external_id}`)) ?? null;

  if (diffTimeInDaysWithCurrentDay(diffDay) >= 2 && !is2Day) {
    localStorage.setItem(`rdaf-2-${userInfo?.external_id}`, "1");
    if (diffTimeInDaysWithCurrentDay(diffDay) === 2) {
      sendAfficeFromStore({
        status: USER.AFFISE_AFTER_REGISTER_2_DAY,
        info: userInfo,
      });
    }
  }

  if (diffTimeInDaysWithCurrentDay(diffDay) >= 6 && !is6Day) {
    localStorage.setItem(`rdaf-6-${userInfo?.external_id}`, "1");
    if (diffTimeInDaysWithCurrentDay(diffDay) === 6) {
      sendAfficeFromStore({
        status: USER.AFFISE_AFTER_REGISTER_6_DAY,
        info: userInfo,
      });
    }
  }
}
