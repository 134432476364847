import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import _ from "lodash";

import { postRequest } from "utils/requests";

import { getIsOpenFilter } from "redux/interface/selectors";
import { selectMyProfile, selectUser } from "redux/auth/selectors";
import { setLike } from "redux/girlsList/operations";

import { clickFilter } from "redux/interface/slice";
import {
  defaultFilters,
  LOOKING_GENDER_MAN,
  LOOKING_GENDER_WOMAN,
} from "data/config";
import { parserFilterForFetch } from "utils/common";

import classes from "./LikeHerPage.module.scss";

const useLikeHerPage = () => {
  const gender = useSelector(selectUser)?.user_info?.gender;
  const search_multigender = useSelector(selectUser)?.search_multigender;

  const [pagination, setPagination] = useState(1);
  const [valuesFilter, setValuesFilter] = useState({
    ...defaultFilters,
    gender: gender === 0 ? LOOKING_GENDER_MAN[0] : LOOKING_GENDER_WOMAN[0],
  });
  const [girlsList, setGirlsList] = useState([]);

  // state for swipe
  const [isOpenFirst, setIsoOpenFirst] = useState(true);
  const [isStartSwipe, setIsStartSwipe] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [isSwipeTriggered, setIsSwipeTriggered] = useState(false);

  const [currentCard, setCurrentCard] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setPagination(1);
    postRequest(
      `/api/v3/search/list/${1}`,
      parserFilterForFetch(valuesFilter, search_multigender, gender)
    ).then((res) =>
      setGirlsList([...res?.response]?.filter((item) => item?.liked !== 1))
    );
    return () => {
      dispatch(clickFilter("close"));
    };
    // eslint-disable-next-line
  }, [valuesFilter, gender]);

  useEffect(() => {
    if (currentCard === girlsList?.length - 10 && isStartSwipe) {
      handleScroll();
    }
    // eslint-disable-next-line
  }, [currentCard]);

  // !!!!! Проверить на стейдже
  // useEffect(() => {
  //   setValuesFilter({
  //     ...defaultFilters,
  //     gender: gender === 0 ? LOOKING_GENDER_MAN[0] : LOOKING_GENDER_WOMAN[0],
  //   });
  //   // eslint-disable-next-line
  // }, [gender]);

  const onChangeOption = (name, value) => {
    setValuesFilter({ ...valuesFilter, [name]: value });
  };

  // const isNotDefaultFilter = _.isEqual(valuesFilter, defaultFilters);

  const filtersOptions = {
    ...useSelector(selectMyProfile)?.referencies_list,
    gender_list: gender === 0 ? LOOKING_GENDER_MAN : LOOKING_GENDER_WOMAN,
  };
  // const userExternalId = useSelector(selectMyProfile)?.user_detail?.external_id;

  const isOpenFilter = useSelector(getIsOpenFilter);

  const modalFilterClose = () => {
    dispatch(clickFilter("close"));
  };

  // const handleProfile = (id) => {
  //   navigate(`/profile-woman/${id}`);
  // };

  const handleChat = (chat_uid, id) => {
    navigate(`/chat/${chat_uid}?profile=${id}`);
  };

  const handleScroll = () => {
    setPagination(pagination + 1);

    postRequest(
      `/api/v3/search/list/${pagination + 1}`,
      parserFilterForFetch(valuesFilter, search_multigender, gender)
    ).then((res) =>
      setGirlsList([
        ...girlsList,
        ...[...res.response]?.filter((item) => item?.liked !== 1),
      ])
    );
  };

  // const handleClearFilters = () => {
  //   setValuesFilter({ ...defaultFilters });
  // };

  const handleCard = (id) => {
    navigate(`/profile-woman/${id}`);
  };

  const handleSimilar = (external_id) => {
    navigate(`/profile-woman/${external_id}`);
  };

  ////////////////////////////////////////////////////

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
    setIsSwipeTriggered(false);
  };

  const handleTouchMove = (e, cardId = "", card2Id = "") => {
    const touch = e.touches[0];
    setCurrentX(touch.clientX);

    const deltaX = touch.clientX - startX;
    const deltaY = touch.clientY - startY;
    const isHorizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY);

    if (isHorizontalSwipe) {
      const cardElement = document.getElementById(cardId);
      const card2Element = document.getElementById(card2Id);

      if (deltaX < -50) {
        cardElement?.classList.add(classes.left);
        card2Element?.classList.add(classes.show);
        cardElement?.classList.remove(classes.right);
      } else if (deltaX > 50) {
        cardElement?.classList.add(classes.right);
        card2Element?.classList.add(classes.show);
        cardElement?.classList.remove(classes.left);
      } else {
        cardElement?.classList.remove(classes.left);
        cardElement?.classList.remove(classes.right);
      }
      setIsSwipeTriggered(true);
    }
  };

  const handleTouchEnd = (cardId) => {
    const deltaX = currentX - startX;

    if (Math.abs(deltaX) > 50 && isSwipeTriggered) {
      if (deltaX > 0) {
        swipeRight(cardId);
      } else {
        swipeLeft(cardId);
      }
    } else {
      const cardElement = document.getElementById(cardId);
      cardElement?.classList?.remove(classes.left);
      cardElement?.classList?.remove(classes.right);
    }
  };

  const swipeLeft = (cardId) => {
    if (!isStartSwipe) {
      setIsStartSwipe(true);
    }
    if (isOpenFirst) setIsoOpenFirst(false);
    const cardElement = document.getElementById(
      cardId || girlsList[girlsList?.length - 1]?.id
    );
    cardElement?.classList?.add(classes.left);
    cardElement?.classList?.add(classes.disabled);

    setCurrentCard((prev) => prev + 1);
  };

  const swipeBack = () => {
    setCurrentCard((prev) => prev - 1);
  };

  const swipeRight = (cardId) => {
    if (!isStartSwipe) {
      setIsStartSwipe(true);
    }
    if (isOpenFirst) setIsoOpenFirst(false);
    const cardElement = document.getElementById(cardId);
    cardElement?.classList?.add(classes.right);
    cardElement?.classList?.add(classes.disabled);

    dispatch(setLike(girlsList[currentCard]?.external_id));
    setCurrentCard((prev) => prev + 1);
  };

  return {
    girlsList,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    swipeLeft,
    swipeRight,
    handleChat,
    isOpenFirst,
    currentCard,

    handleSimilar,

    isOpenFilter,
    modalFilterClose,
    filtersOptions,
    onChangeOption,
    valuesFilter,
    handleCard,
    swipeBack,
  };
};

export default useLikeHerPage;
