import { useNavigate, useLocation } from "react-router-dom";
import { MIRROR } from "data/mirrorConfig";

import cn from "classnames";

import {
  Header,
  Navigation,
  ModalAlert,
  ModalFilter,
  Transactions,
  ModalContent,
  BanerConfirmMail,
  ModalWelcome,
  Loader,
  ModalCongratulation,
  // ModalHowToStart,
  // ModalPersonalInformation,
  ModalRegisteredUser,
  Tooltips,
} from "components/atomsKit/Elements";
import RouterMain from "./RouterMain";

import { navConfig, configServiceBtn } from "data/config";

import useIsAllWindow from "hooks/useIsAllWindow";
import { useHandleRouting } from "hooks";
import useLayout from "./useLayout";

import useIsLoading from "hooks/useIsLoading";
import useHandleActiveBlocks from "hooks/useHandleActiveBlocks";

import { findButtonConfig, birthdayModalText } from "utils/common";

// @ts-ignore
import classes from "./Layout.module.scss";

const Layout = () => {
  const {
    isRefreshing,
    isLoggedIn,
    handleCloseGlobalModal,
    handleFilter,
    isOpenFilter,
    handleAlert,
    isOpenAlert,
    handleCloseAlert,
    navInfo,
    globalModalInfo,
    handleCloseModalContent,
    modalContent,
    transactions,
    notifications,
    handleDelTransaction,
    handleDelNotification,
    handleTransaction,
    userInfo,

    isOpenBanerConfirm,
    setIsOpenBanerConfirm,
    isOpenBanerWelcome,
    setIsOpenBanerWelcome,

    isUserBirthdayModal,
    closeUserBirthdayModal,
    birthdayBonusCredits,

    isOpenOneStepModal,
    setIsOpenOneStepModal,
  } = useLayout();

  const { launch } = useHandleRouting();

  const navigate = useNavigate();
  const location = useLocation();

  const { isGlobalLoading } = useIsLoading();

  const { browsPanelHide } = useIsAllWindow();

  const { isVip } = useHandleActiveBlocks();

  if (isRefreshing) {
    return <b></b>;
  }

  return (
    <>
      <ModalCongratulation
        modalIsOpen={isUserBirthdayModal}
        closeModal={closeUserBirthdayModal}
        textHeader={"CONGRATULATION!"}
        titleText={`Dear ${userInfo?.name}`}
        messages={[birthdayModalText(birthdayBonusCredits)]}
        icon="user_birthday"
        textBtn="Close"
        onClick={closeUserBirthdayModal}
      />
      <ModalRegisteredUser
        modalIsOpen={isOpenOneStepModal}
        closeModal={() => {
          setIsOpenOneStepModal(false);
        }}
      />
      {/* <ModalPersonalInformation modalIsOpen/>,
       */}
      <ModalAlert
        modalIsOpen={globalModalInfo.isOpen}
        description={globalModalInfo.description}
        closeModal={
          globalModalInfo.text === "You lack credits"
            ? () => {
                launch();
                handleCloseGlobalModal();
              }
            : handleCloseGlobalModal
        }
        allScreen={globalModalInfo.allScreen}
        onClick={globalModalInfo.onClick}
        textAlertBtn={globalModalInfo.btnText}
        icon="x1"
        allertMessage={globalModalInfo.text}
        mode={globalModalInfo.mode}
        zIndex={2000}
      />
      <BanerConfirmMail
        modalIsOpen={isOpenBanerConfirm}
        closeModal={() => {
          setIsOpenBanerConfirm(false);
          localStorage.setItem("modalConfirmClose", "1");
        }}
      />
      <ModalWelcome
        balance={userInfo?.credits + userInfo?.bonus_credits}
        modalIsOpen={isOpenBanerWelcome}
        closeModal={() => {
          setIsOpenBanerWelcome(false);
          localStorage.setItem("modalWelcome", "1");
        }}
      />
      {/* <ModalHowToStart modalIsOpen={isOpenBanerWelcome} /> */}
      {modalContent.isOpen && (
        <ModalContent
          modalIsOpen={modalContent.isOpen}
          closeModal={handleCloseModalContent}
          items={modalContent.data}
        />
      )}
      {["/search", "/like"].includes(window.location.pathname) && (
        <Tooltips
          transactions={[...transactions, ...notifications]}
          handleDelTransaction={handleDelTransaction}
          handleDelNotification={handleDelNotification}
          handleTransaction={handleTransaction}
          userInfo={userInfo}
        />
      )}
      <ModalFilter
        modalIsOpen={isOpenAlert}
        closeModal={handleCloseAlert}
        position="right"
      >
        <Transactions
          haveNewMemberActivity={transactions.length > 0}
          haveNewNotification={notifications.length > 0}
          transactions={transactions}
          notifications={notifications}
          handleDelTransaction={handleDelTransaction}
          handleDelNotification={handleDelNotification}
          handleTransaction={handleTransaction}
          handleCloseAlert={handleCloseAlert}
          userInfo={userInfo}
        />
      </ModalFilter>
      <Loader isLoading={isGlobalLoading} />
      <div
        className={cn(isLoggedIn && classes.wrap)}
        style={{
          height: isLoggedIn
            ? `${browsPanelHide - 90}px`
            : `${browsPanelHide}px`,
        }}
      >
        {isLoggedIn && (
          <Header
            onClickService={
              findButtonConfig(
                configServiceBtn,
                handleFilter,
                location,
                navigate
              )?.onClick
            }
            iconService={
              findButtonConfig(
                configServiceBtn,
                handleFilter,
                location,
                navigate
              )?.icon
            }
            isOpenFilter={isOpenFilter}
            textService={
              findButtonConfig(
                configServiceBtn,
                handleFilter,
                location,
                navigate
              )?.text
            }
            colorService={"white"}
            onClickLogo={() => {}}
            onCLickAllert={handleAlert}
            isOpenAlert={isOpenAlert}
            alertCounter={transactions.length + notifications.length}
            logo={
              isVip
                ? // @ts-ignore
                  MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_LOGO_VIP
                : // @ts-ignore
                  MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_LOGO
            }
          />
        )}

        <RouterMain />
        {isLoggedIn && (
          <Navigation
            onSecondAction={() => handleFilter("close")}
            navConfig={{
              ...navConfig,
              Chat: { ...navConfig.Chat, counter: navInfo.chatCount },
              Letters: { ...navConfig.Letters, counter: navInfo.mailBoxCount },
            }}
          />
        )}
      </div>
    </>
  );
};

export default Layout;
