import { Link, useLocation } from "react-router-dom";

import { CheckboxTogle, Input, Box } from "components/atomsKit/Elements";

import cn from "classnames";

// @ts-ignore
import classes from "./ChatFilterPanel.module.scss";

interface IProps {
  className?: string;
  handleIsOnline: () => void;
  isOnline: boolean;
  templateProp?: any;
  search?: string;
  setSearch?: (e: string) => void;
  configFilterChat?: any;
  hideSerchPanel?: boolean;
}

const getFilterFromLink = (link: string): string => {
  const match = link.match(/filter=([^&]*)/) || "";
  return match ? match[1] : "";
};

const ChatFilterPanel = ({
  handleIsOnline,
  isOnline,
  search,
  setSearch,
  configFilterChat,
  hideSerchPanel,
}: IProps) => {
  const location = useLocation();
  const { search: param } = location;
  const queryParams = new URLSearchParams(param);
  const filterValue = queryParams.get("filter") || "";

  return (
    <section className={classes.panelWrap}>
      <ul className={classes.ul}>
        {configFilterChat &&
          configFilterChat.map(
            (
              item: { name: string; link: string; haveNew?: boolean },
              index: number
            ) => (
              <Link
                key={item.name}
                to={item.link}
                className={cn(
                  classes.link,
                  (filterValue === getFilterFromLink(item.link) ||
                    (index === 0 && !filterValue)) &&
                    classes.link_isActive
                )}
              >
                <p className={classes.name}>
                  {item.name}
                  {item && item.haveNew && <span className={classes.new} />}
                </p>
              </Link>
            )
          )}
      </ul>
      {setSearch && !hideSerchPanel && (
        <Box flex width100 justify={"between"}>
          <CheckboxTogle
            value="ONLINE"
            onChange={handleIsOnline}
            checked={isOnline}
            className={classes.onlineWrap}
          />
          <Input
            placeholder="Search ID"
            // @ts-ignore
            onChange={(e) => setSearch(e.target.value)}
            // @ts-ignore
            value={search}
            className={classes.search}
          />
        </Box>
      )}
    </section>
  );
};

export default ChatFilterPanel;
