import cn from "classnames";

import { MIRROR } from "data/mirrorConfig";

import {
  MailTextBox,
  ProfileHeder,
  PageWrap,
  Box,
  InputModern,
  Button,
  ModalAlert,
  AddFileModal,
  SliderMIniPhoto,
  PanelWithButton,
} from "components/atomsKit/Elements";

import BanersSubPage from "pages/BanersSubPage/BanersSubPage";

import {
  Timeonchat,
  Textonchat,
  Image,
  NameonChat,
} from "components/designAtom/Elements";

import useLetterProfilePage from "./useLetterProfilePage";
import usePrice from "hooks/usePrice";
import useHandleActiveBlocks from "hooks/useHandleActiveBlocks";

import { cutTextforToltip } from "utils/common";

// @ts-ignore
import classes from "./LetterProfilePage.module.scss";

const LetterProfilePage = () => {
  const {
    womanData,
    chats,
    valueMessage,
    handleValueMessage,
    onChangeSendMessage,
    wrapListRef,
    onChangeSetbookmark,
    onChangeTrashmail,
    handleFavorite,
    isFavorite,
    handleDropBtnLeter,
    handleDropBtnBlock,
    handleDropBtnComplain,
    isOpenModalAddBlock,
    setIsOpenModalAddBlock,
    handleAddBlock,
    isOpenModalComplaint,
    setIsOpenModalComplaint,
    changeFileToComplaint,
    selectFileToComplaint,
    setSelectFileToComplaint,
    handleMail,
    mail,
    onChangeDescribe,
    describe,
    sendSupportReq,
    handleOpenUpploadFile,
    handleProfile,
    handleContent,

    selectedMedia,
    handleUploadFile,
    navConfig,
    isOpenModaAddFile,
    setIsOpenModaAddFile,
    handleUploadNavBtn,
    uploadFiles,
    handleItemMedia,
    handleSendMedias,
    handleDelMedia,
    handlePay,
    user_name,

    handleSendGift,
    connect,
  } = useLetterProfilePage();

  const price = usePrice();

  const { isHideGift } = useHandleActiveBlocks();

  const isNotPayed = (chat: any) => {
    const isUserCard = chat?.mail?.male_id === chat?.mail?.sender_id;
    const paid_read = chat?.mail?.paid_read;
    const payed = chat?.mail?.payed;
    const read_status = chat?.mail?.read_status;

    return !isUserCard && paid_read === 0 && payed === 1 && read_status === 0
      ? true
      : false;
  };

  return (
    <>
      <PageWrap startPositionY={192} height100>
        <>
          <div className={classes.baners}>
            <BanersSubPage />
            {womanData && (
              <>
                <ModalAlert
                  modalIsOpen={isOpenModalAddBlock}
                  closeModal={() => setIsOpenModalAddBlock(false)}
                  icon="x1"
                  allertMessage={
                    "Do you really want to add her to the block list?"
                  }
                >
                  <Box
                    flex
                    width100
                    justify={"center"}
                    directionColumn
                    align="center"
                  >
                    <NameonChat
                      textAlign="center"
                      text={`You won't receive messages from herany longer. To unblock the member, go to "Block list" menu section in your profile`}
                    />
                    <Button
                      margin="20px 0 0 0"
                      padding="0 20px"
                      onClick={handleAddBlock}
                      text={"Аdd to block list"}
                      icon="x1"
                      fitContent
                    />
                    <Button
                      margin="10px 0 0 0"
                      padding="0 20px"
                      onClick={() => setIsOpenModalAddBlock(false)}
                      text={"Cancel"}
                      fitContent
                      isWhite
                      borderOff
                    />
                  </Box>
                </ModalAlert>
                <ModalAlert
                  modalIsOpen={isOpenModalComplaint}
                  closeModal={() => setIsOpenModalComplaint(false)}
                  icon="x1"
                  allertMessage={"Complaint"}
                >
                  <form onSubmit={sendSupportReq}>
                    <InputModern
                      type="email"
                      placeholder="Your E-Mail"
                      value={mail}
                      onChange={(e) => {
                        handleMail(e.target.value);
                      }}
                    />
                    <Textonchat
                      text={`Member ID: ${womanData?.public_external_id}`}
                      size={12}
                      isSpan
                      margin="20px 0 20px 0"
                      className={classes.text}
                    />
                    <InputModern
                      error
                      isTextArea
                      onChange={(e: any) => onChangeDescribe(e.target.value)}
                      placeholder={"Please, describe the reason"}
                      value={describe}
                      className={classes.messageInput}
                    />
                    {!selectFileToComplaint ? (
                      <label className={classes.uploadBtn}>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="popup_inp_file1"
                          onChange={changeFileToComplaint}
                        />
                        <Button
                          margin="20px 0 0 0"
                          iconSvg="support-file-ico"
                          text="Choose your file, please!!!"
                          isWhite
                          className={classes.btnDecor}
                          type="button"
                        />
                      </label>
                    ) : (
                      <Box
                        marginTop="10px"
                        flex
                        align={"center"}
                        justify={"between"}
                      >
                        <Box flex align={"center"}>
                          <Image
                            name="file_uploaded"
                            className={classes.imgUpload}
                          />
                          <Textonchat
                            // @ts-ignore
                            text={`File uploaded: ${cutTextforToltip(
                              // @ts-ignore
                              selectFileToComplaint?.target?.files[0]?.name,
                              10
                            )} (${Math.round(
                              // @ts-ignore
                              selectFileToComplaint?.target?.files[0]?.size /
                                1024
                            )} kb)`}
                            size={12}
                            isSpan
                            margin="0 0 0 10px"
                            className={classes.text}
                          />
                        </Box>
                        <button
                          onClick={() => setSelectFileToComplaint(undefined)}
                        >
                          <Image name="Trash-red" />
                        </button>
                      </Box>
                    )}

                    <p className={classes.ligalText}>
                      <Timeonchat
                        isSpan
                        text="If you haven't mentioned your e-mail address during registration, please, contact us at "
                      />
                      <a
                        href={`mailto:${
                          // @ts-ignore
                          MIRROR[process.env.REACT_APP_MIRROR_NAME]
                            .REACT_APP_SUPPORT_MAIL
                        }`}
                        className={classes.mailLigal}
                      >
                        {
                          // @ts-ignore
                          MIRROR[process.env.REACT_APP_MIRROR_NAME]
                            .REACT_APP_SUPPORT_MAIL
                        }
                      </a>
                    </p>

                    <Button
                      margin="20px 0 0 0"
                      padding="0 20px"
                      text={"Send"}
                      type="submit"
                    />
                  </form>
                </ModalAlert>
                <AddFileModal
                  price={price}
                  selectedMedia={selectedMedia}
                  handleUploadFile={handleUploadFile}
                  uploadFileNavConfig={navConfig}
                  modalIsOpen={isOpenModaAddFile}
                  closeModal={() => setIsOpenModaAddFile(false)}
                  handleUploadNavBtn={handleUploadNavBtn}
                  uploadFiles={uploadFiles}
                  handleItemMedia={handleItemMedia}
                  handleSendMedias={handleSendMedias}
                  handleDelMedia={handleDelMedia}
                  handleContent={handleContent}
                />
                <ProfileHeder
                  handleProfile={handleProfile}
                  name={womanData?.name}
                  age={womanData?.age}
                  external_id={womanData?.external_id}
                  public_external_id={womanData?.public_external_id}
                  country={womanData?.country_code}
                  city={womanData?.city_name}
                  isOnline={womanData?.online === 0 ? false : true}
                  isFavorire={isFavorite}
                  imgLink={womanData?.photo_link}
                  country_icon={womanData?.country_icon}
                  handleFavorite={handleFavorite}
                  bodyMoreBtn={
                    <>
                      <Button
                        onClick={handleDropBtnLeter}
                        text="Write a message"
                        isWhite
                        borderOff
                        iconSvg="chat"
                        fitContent
                        bgImg
                        classNameIcon="btnIco"
                      />
                      <Button
                        onClick={handleDropBtnBlock}
                        text="Add to block list"
                        isWhite
                        borderOff
                        iconSvg="c3-top-block"
                        fitContent
                        bgImg
                        classNameIcon="btnIco"
                      />
                      <Button
                        onClick={handleDropBtnComplain}
                        text="To complain"
                        isWhite
                        borderOff
                        iconSvg="c3-top-attention"
                        fitContent
                      />
                    </>
                  }
                />
              </>
            )}
            {!isHideGift && (
              <PanelWithButton
                buttonConfig={[
                  {
                    name: "Gift",
                    onClick: () => {
                      handleSendGift();
                    },
                  },
                ]}
              />
            )}
          </div>
          <div className={classes.pageContent}>
            <ul className={classes.wrap} id={"messagerBox"} ref={wrapListRef}>
              {chats
                ?.slice()
                .reverse()
                .map((chat: any, index: number) => (
                  <li key={index} id={chat?.mail?.id}>
                    <MailTextBox
                      price={price}
                      blure={isNotPayed(chat)}
                      handlePay={handlePay}
                      handleContent={handleContent}
                      user_name={user_name}
                      name={womanData?.name}
                      age={womanData?.age}
                      data={chat}
                      onChangeSetbookmark={onChangeSetbookmark}
                      onChangeTrashmail={onChangeTrashmail}
                    />
                  </li>
                ))}
              <li />
            </ul>
          </div>
        </>
      </PageWrap>
      <Box align={"center"} className={classes.wrpaMessageSenderBox}>
        <Box flex>
          <InputModern
            infoText={`${valueMessage?.length} symbols entered`}
            isTextArea
            onChange={handleValueMessage}
            placeholder={"Start writing your letter here"}
            value={valueMessage}
            className={cn(
              classes.messageInput,
              selectedMedia?.length > 0 && classes.messageInput_attach
            )}
          />
        </Box>
        {selectedMedia?.length > 0 && (
          <div>
            <div className={classes.line} />
            <SliderMIniPhoto
              handleDel={handleItemMedia}
              className={classes.fileInMessager}
              girlsList={selectedMedia}
            />
          </div>
        )}
        <Box flex justify={"between"} align={"center"}>
          <button
            className={classes.btnSendPhoto}
            onClick={handleOpenUpploadFile}
          >
            <Image
              name="attach-ico-blue"
              imgRotate={45}
              bgImg
              classNameIcon="btnIco"
            />
            <p className={classes.textBtnScrep}>Add files</p>
          </button>

          <Box flex justify={"center"} align={"center"}>
            <Timeonchat
              text={`The cost of sending a letter is ${
                connect === 1 ? price.SENT_MAIL_SECOND : price.SENT_MAIL_FIRST
              } credits`}
            />
            <Button
              margin="0 0 0 5px"
              fitContent
              onClick={() => onChangeSendMessage()}
              text="Send"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LetterProfilePage;
