import { Fragment } from "react";

import { Box, Avatar, AudioPlayer } from "components/atomsKit/Elements";

import { Textonchat, Timeonchat, Image } from "components/designAtom/Elements";

import { findStiker, formatDateTime, renderLastMessage } from "utils/common";

import cn from "classnames";

// @ts-ignore
import classes from "./Message.module.scss";

interface IProps {
  className?: string;
  isInMessage?: boolean | number;
  text: string;
  thumb_link?: string;
  type: string;
  time: string;
  isRead?: boolean;
  avatar?: string;
  user_name?: string;
  name?: string;
  scrollDown: any;
  id: any;
  filename: string;
  handleContent?: any;
  payed: boolean;
  price?: any;
  handlePlayAudio?: any;
}

const Message = ({
  isInMessage,
  text,
  thumb_link,
  type,
  time,
  isRead,
  avatar,
  scrollDown,
  name,
  user_name,
  id,
  filename,
  handleContent,
  payed,
  price,
  handlePlayAudio,
}: IProps) => {
  if (type === "SENT_TEXT" && text === "") return <></>;

  return (
    <Box className={cn(classes.wrap, isInMessage && classes.wrap_isInMessage)}>
      {isInMessage && (
        <Avatar
          user_name={isInMessage === 0 ? name : user_name}
          imgLink={avatar}
          small
          className={cn(classes.avatar, classes.avatar_isInMessage)}
        />
      )}
      <Box>
        <Box
          className={cn(
            classes.messageWrap,
            isInMessage && classes.messageWrap_isInMessage
          )}
        >
          {type === "SENT_STICKER" ? (
            <img
              src={findStiker(text) as any}
              alt=""
              width={"200"}
              onLoad={scrollDown}
              loading="lazy"
            />
          ) : type === "SENT_IMAGE" ? (
            <img
              alt=""
              src={text}
              onLoad={scrollDown}
              className={classes.sticker}
              onClick={() =>
                handleContent({
                  payed: true,
                  original: text,
                  thumbnail: text,
                  type: "SENT_IMAGE",
                })
              }
              loading="lazy"
            />
          ) : type === "SENT_VIDEO" ? (
            <Box position={"relative"}>
              {!payed && (
                <Timeonchat
                  text={`Viewing this video costs ${price?.GET_VIDEO_NEW} credits`}
                  margin="-5px 0 5px 0"
                />
              )}
              <Image name="play-btn" className={classes.imgPlay} />
              <video
                id={id}
                preload="metadata"
                className={classes.video}
                onClick={() =>
                  handleContent({
                    payed: payed,
                    original: text,
                    thumbnail: text,
                    id: id,
                    type: "SENT_VIDEO",
                  })
                }
                poster={thumb_link}
              >
                <source src={text} type="video/mp4" />
              </video>
            </Box>
          ) : type === "SENT_AUDIO" ? (
            <Box className={classes.audioWrap}>
              {!payed && (
                <Timeonchat
                  text={`Viewing this video costs ${price?.SENT_AUDIO_MAIL_NEW} credits`}
                  margin="-5px 0 5px 0"
                />
              )}
              <Box className={classes.audioContent}>
                <AudioPlayer
                  id={id}
                  isPlay={payed}
                  isMessage={true}
                  src={text}
                  name={filename}
                  className={classes.audioPlayerWrap}
                  handleContent={handlePlayAudio}
                />
              </Box>
            </Box>
          ) : type === "SENT_WINK" && text === "Send a wink 😉" ? (
            <Image name="wink" />
          ) : (
            <Textonchat
              className={classes.text}
              text={renderLastMessage(type, text, isInMessage, name, true)
                .split("\n")
                .map((line: any, index: any) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
            />
          )}
        </Box>
        <Box flex align={"center"} justify={"between"}>
          <Timeonchat text={formatDateTime(time)} className={classes.time} />
          {!isInMessage && (
            <Image name={`${isRead ? "readed" : "send"}`} color="red" />
          )}
        </Box>
      </Box>
      {!isInMessage && (
        <Avatar
          small
          className={cn(classes.avatar)}
          imgLink={avatar}
          user_name={user_name}
        />
      )}
    </Box>
  );
};

export default Message;
