import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getUserProfile } from "redux/girlsList/selectors";
import { selectUser, selectMyProfile } from "redux/auth/selectors";

import { changeProfileForGift, togleGlobalModal } from "redux/interface/slice";
import { getSayHalloList } from "redux/interface/selectors";

import { getRequest } from "utils/requests";

import { sendMessage } from "redux/chat/operations";
import { userProfile, setFavorite, setLike } from "redux/girlsList/operations";

const useUserPage = () => {
  const [videoLink, setVideoLink] = useState();
  const profile = useSelector(getUserProfile);
  const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);
  const [isOpenModalSayHello, setIsOpenModalSayHello] = useState(false);
  const [isOpenModalContact, setIsOpenModalContact] = useState(false);
  const [similarList, setSimilarList] = useState([]);
  const [isOpenModalWink, setIsOpenModalWink] = useState(false);

  const [isLikeGirl, setIsLikeGirl] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { external_id } = useParams();
  const userExternalId = useSelector(selectUser)?.user_info?.external_id;
  const sayHalloList = useSelector(getSayHalloList);
  const user_detail = useSelector(selectMyProfile)?.user_detail;

  useEffect(() => {
    if (profile?.profile?.result?.profile?.external_id) {
      getRequest(
        `/api/v3/woman/similar/profile/${profile?.profile?.result?.profile?.external_id}`
      ).then((res) => {
        setSimilarList(res.result);
      });
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    dispatch(userProfile(external_id));
    // eslint-disable-next-line
  }, [external_id]);

  useEffect(() => {
    const video = profile?.content?.result.find(
      (item) => item?.content_type === "video"
    );
    if (video) {
      getRequest(
        `/api/v3/click-history/vimeo/thumbnail?thumbnailID=${video?.thumbnail_id}`
      )
        .then((res) =>
          setVideoLink({ ...res?.data[0], thumbnail_id: video?.id })
        )
        .catch((err) => {
          console.log(err);
        });
    } else {
      setVideoLink(undefined);
    }
  }, [profile]);

  const handleVideoPlay = (thumbnail_id) => {
    getRequest("/api/v3/search/check-video").then(() => {
      getRequest(`/api/video/${thumbnail_id}`)
        .then((res) => {
          setIsOpenModalVideo(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleSimilar = (external_id) => {
    navigate(`/profile-woman/${external_id}`);
  };

  const handleFavorite = (chat_uid) => {
    openMessageAboutBlocked() ||
      dispatch(
        setFavorite({
          chat_uid: chat_uid,
          favorite:
            profile?.profile?.result?.chat?.female_favorite === 0 ? 1 : 0,
          user_id: userExternalId,
        })
      );
  };

  const handleLike = (external_id) => {
    openMessageAboutBlocked() ||
      (() => {
        setIsLikeGirl(true);
        dispatch(setLike(external_id));
      })();
  };

  const handleChat = (chat_uid, id) => {
    openMessageAboutBlocked() || navigate(`/chat/${chat_uid}?profile=${id}`);
  };

  const handleLetters = (id) => {
    openMessageAboutBlocked() || navigate(`/letter?profileId=${id}`);
  };

  const openMessageAboutBlocked = () => {
    if (profile?.profile?.result?.profile?.is_blocked) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "This member is on the block list.",
          btnText: "Got it",
        })
      );
      return true;
    }
    return false;
  };

  const handleSayHello = () => {
    openMessageAboutBlocked() || setIsOpenModalWink(true);
  };

  const handleWink = (wink) => {
    const reqBody = {
      recipient_id: external_id,
      sender_id: userExternalId,
      filename: "",
      message_content: wink?.message_content,
      message_type: "SENT_WINK",
    };
    dispatch(sendMessage(reqBody));
    setIsOpenModalWink(false);
    setIsOpenModalSayHello(true);
  };

  const handleSendGift = () => {
    const infoForSend = {
      id: profile?.profile?.result?.profile?.external_id,
      public_external_id: profile?.profile?.result?.profile?.public_external_id,
      name: profile?.profile?.result?.profile?.name,
      age: profile?.profile?.result?.profile?.age,
      isOnline: profile?.profile?.result?.profile?.online,
      flag: profile?.profile?.result?.profile?.country_icon,
      avatar: profile?.profile?.result?.profile?.avatars?.avatar_title,
      city: profile?.profile?.result?.profile?.city_name,
    };

    dispatch(changeProfileForGift(infoForSend));
    navigate("/gifts");
  };

  return {
    profile,
    handleSimilar,
    videoLink,
    handleVideoPlay,
    setIsOpenModalVideo,
    isOpenModalVideo,
    handleFavorite,
    handleChat,
    handleLike,
    handleLetters,
    isLikeGirl,
    handleSayHello,
    isOpenModalSayHello,
    setIsOpenModalSayHello,
    isOpenModalContact,
    setIsOpenModalContact,
    handleSendGift,
    similarList,
    sayHalloList,
    isOpenModalWink,
    setIsOpenModalWink,
    handleWink,
    user_detail,
  };
};

export default useUserPage;
