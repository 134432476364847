import ClientJS from "clientjs"; // eslint-disable-line

import { osVersion, osName, isMobile } from "react-device-detect";
import { diffTimeInDaysWithCurrentDay } from "utils/common";
import * as USER from "data/user";

import { postRequest, getRequest } from "utils/requests";

export function checkFraud(externalID, siteID) {
  try {
    const client = new window.ClientJS();
    const clientData = {
      getCustomFingerprint: client.getCustomFingerprint(
        client.getBrowserData().ua,
        client.getCanvasPrint()
      ),
      getEngine: client.getEngine(),
      getFingerprint: client.getFingerprint(),
      getAvailableResolution: client.getAvailableResolution(),
      getBrowser: client.getBrowser(),
      getBrowserData: client.getBrowserData(),
      getBrowserMajorVersion: client.getBrowserMajorVersion(),
      getCanvasPrint: client.getCanvasPrint(),
      getCPU: client.getCPU(),
      getSoftwareVersion: client.getSoftwareVersion(),
      getDevice: client.getDevice(),
      getDeviceType: client.getDeviceType(),
      getDeviceVendor: client.getDeviceVendor(),
      getScreenPrint: client.getScreenPrint(),
      getPlugins: client.getPlugins(),
      getTimeZone: client.getTimeZone(),
      getLanguage: client.getLanguage(),
    };

    return getRequest("https://extreme-ip-lookup.com/json").then((ipData) => {
      if (ipData) {
        return postRequest("/api/api/v3/fraud/register", {
          ...clientData,
          ...ipData,
          ...{
            external_id: externalID,
            site_id: siteID,
          },
        });
      }
      return false;
    });
  } catch (err) {
    /* empty */
  }
}

const getBinomo = (cnv_status, clickid) => {
  const eventIndex = +USER.BINOMO_EVENT?.indexOf(cnv_status) + 1;
  const addStatusTail = USER.BINOMO_EVENT?.includes(cnv_status)
    ? `&cnv_status={cnv_status}&event${eventIndex}=1`
    : `&cnv_status=${cnv_status}`;

  return getRequest(
    `https://binom.love-2-date.com/click.php?cnv_id=${clickid}&payout={payout}${addStatusTail}`
  );
};

export async function sendBinomoFromStore({
  externalID,
  siteID,
  status,
  clickid,
  utm_aff_hash,
  info,
}) {
  try {
    if (externalID && siteID && !+info?.ghost) {
      postRequest("/api/v3/click-history/events", {
        external_id: externalID,
        event: status,
      });
      await checkFraud(externalID, siteID);

      if (status && clickid) {
        if (utm_aff_hash) {
          const checkOfferReq = await postRequest("/api/tail/checkAffOffer", {
            external_id: externalID,
            utm_aff_hash: utm_aff_hash,
            device: {
              device: isMobile ? "mob" : "desk",
              os: `${osName} ${osVersion}`,
            },
            status,
          });
          if (checkOfferReq?.status) getBinomo(status, clickid);
        } else getBinomo(status, clickid);
      }
    }
  } catch (e) {
    /* empty */
  }
}

export function diffBinomoByRegisterDate(
  userInfo,
  tail,
  clickid,
  utm_aff_hash
) {
  const diffDay = JSON.parse(
    localStorage.getItem(`rd-${userInfo?.external_id}`)
  );
  const is2Day =
    JSON.parse(localStorage.getItem(`rdb-2-${userInfo?.external_id}`)) ?? null;
  const is6Day =
    JSON.parse(localStorage.getItem(`rdb-6-${userInfo?.external_id}`)) ?? null;

  if (diffTimeInDaysWithCurrentDay(diffDay) >= 2 && !is2Day) {
    localStorage.setItem(`rdb-2-${userInfo?.external_id}`, "1");
    if (diffTimeInDaysWithCurrentDay(diffDay) === 2) {
      sendBinomoFromStore({
        externalID: userInfo?.external_id,
        siteID: userInfo?.site_id,
        status: USER.BINOMO_AFTER_REGISTER_2_DAY,
        clickid: clickid,
        utm_aff_hash: utm_aff_hash,
        tail: tail,
      });
    }
  }

  if (diffTimeInDaysWithCurrentDay(diffDay) >= 6 && !is6Day) {
    localStorage.setItem(`rdb-6-${userInfo?.external_id}`, "1");
    if (diffTimeInDaysWithCurrentDay(diffDay) === 6) {
      sendBinomoFromStore({
        externalID: userInfo?.external_id,
        siteID: userInfo?.site_id,
        status: USER.BINOMO_AFTER_REGISTER_6_DAY,
        clickid: clickid,
        utm_aff_hash: utm_aff_hash,
        tail: tail,
      });
    }
  }
}
