import { useState, useLayoutEffect, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { getReFetch, getReFetchAction } from "redux/interface/selectors";
import { selectUser } from "redux/auth/selectors";
import { togleReFetch } from "redux/interface/slice";

const usePageUtils = (
  config = { startPage: 1, trigerScrollDown: [], rulesSocket: undefined }
) => {
  const { startPage, trigerScrollDown } = config;

  const [isFirstRunPage, setIsFirstRunPage] = useState(true);
  const [pagination, setPagination] = useState(startPage);
  const [onScrollBottom, setOnScrollBottom] = useState(true);
  const [togle, setTogle] = useState(false);

  const [positionScroll, setPositionScroll] = useState();

  const dispatch = useDispatch();
  const wrapListRef = useRef(null);

  const reFetch = useSelector(getReFetch);
  const socketAction = useSelector(getReFetchAction);

  const userInfo = useSelector(selectUser);
  const userExternalId = useSelector(selectUser)?.user_info?.external_id;

  useEffect(() => {
    setIsFirstRunPage(false);
  }, []);

  useLayoutEffect(() => {
    if (
      config.rulesSocket === undefined ||
      config.rulesSocket.func(socketAction)
    ) {
      if (isFirstRunPage === false) {
        setOnScrollBottom(true);
        setPagination(1);
        togleAction();
      }
    }
    // eslint-disable-next-line
  }, [reFetch]);

  useEffect(() => {
    return () => {
      dispatch(togleReFetch("togle"));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (wrapListRef?.current) {
      const currentWrapChatRef = wrapListRef?.current;
      currentWrapChatRef.addEventListener("scroll", handleScroll);
      return () => {
        if (currentWrapChatRef) {
          currentWrapChatRef.removeEventListener("scroll", handleScroll);
        }
      };
    }
    // eslint-disable-next-line
  }, [wrapListRef?.current]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = wrapListRef.current;
    if (scrollHeight <= Math.abs(scrollTop) + Math.abs(clientHeight) + 1) {
      setOnScrollBottom(false);
      setPagination((prevPagination) => prevPagination + 1);
    }
  };

  useEffect(() => {
    if (wrapListRef.current) {
      setTimeout(() => {
        scrollDown();
      }, 0);
    }
    // eslint-disable-next-line
  }, [...trigerScrollDown, wrapListRef?.current?.scrollHeight]);

  const scrollDown = () => {
    if (onScrollBottom) {
      wrapListRef.current.scrollTop = wrapListRef.current.scrollHeight;
    } else {
      if (wrapListRef?.current) {
        const element = document.getElementById(positionScroll);
        if (element) {
          element.scrollIntoView();
          setPositionScroll();
        }
      }
    }
  };

  const togleAction = () => {
    setTogle(!togle);
  };

  return {
    isFirstRunPage,
    pagination,
    setPagination,
    wrapListRef,
    userInfo,
    userExternalId,
    onScrollBottom,
    setOnScrollBottom,
    scrollDown,
    togle,
    togleAction,
    setPositionScroll,
  };
};

export default usePageUtils;
