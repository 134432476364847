import styles from "./styles.module.scss";
import { useSelector } from "react-redux";

import { Image } from "components/designAtom/Elements";

import { MIRROR } from "data/mirrorConfig";

import tut_step_3_girl_1 from "img/tutorial/tut_step_3_girl_1.png";
import tut_step_3_man_1 from "img/tutorial/tut_step_3_man_1.png";
import tut_step_3_girl_2 from "img/tutorial/tut_step_3_girl_2.png";
import tut_step_3_man_2 from "img/tutorial/tut_step_3_man_2.png";

export default function Step3() {
  const userState = useSelector((state) => state?.auth?.user?.user_info);

  const ArrowDownIcon = () => {
    return (
      <svg
        className={styles.step_filter_item_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        height="3"
        viewBox="0 0 5 3"
        fill="#374E6C"
      >
        <path
          d="M4.65668 0.154297L2.78675 1.99858L0.916901 0.154444L0.627441 0.423788L2.78675 2.55335L4.94575 0.423788L4.65668 0.154297Z"
          fillOpacity="0.56"
        />
      </svg>
    );
  };

  const FilterDetailedIcon = () => {
    return (
      <svg
        className={styles.step_filter_item_detailed_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="#446077"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.86592 2.15576L9.70915 2.15576C9.95253 2.15595 10.1909 2.22677 10.3948 2.35963C10.5987 2.49248 10.7597 2.68167 10.8582 2.90422C10.9567 3.12678 10.9884 3.37314 10.9496 3.61339C10.9108 3.85365 10.803 4.07747 10.6395 4.25768L7.76714 7.41611V9.07304C7.76708 9.17487 7.74413 9.27561 7.7 9.36739C7.65588 9.45916 7.59169 9.53984 7.5122 9.60348L5.91285 10.8827C5.91277 10.8828 5.91292 10.8827 5.91285 10.8827C5.79266 10.9791 5.64312 11.0319 5.48913 11.0323C5.38563 11.0328 5.28343 11.0093 5.19052 10.9637C5.07614 10.9081 4.97964 10.8216 4.912 10.7138C4.84406 10.6056 4.80799 10.4805 4.80794 10.3527V7.41611L1.93584 4.25795C1.77228 4.07773 1.6643 3.85365 1.62547 3.61339C1.58665 3.37314 1.6184 3.12678 1.71689 2.90422C1.81537 2.68166 1.97634 2.49248 2.18026 2.35963C2.38417 2.22677 2.62255 2.15595 2.86592 2.15576ZM5.48913 11.0323C5.48875 11.0323 5.48838 11.0323 5.488 11.0323L5.48772 10.6724L5.48995 11.0323C5.48968 11.0323 5.4894 11.0323 5.48913 11.0323ZM2.86608 2.87548C2.76205 2.87559 2.66029 2.90587 2.57314 2.96265C2.48595 3.01945 2.41714 3.10033 2.37505 3.19546C2.33295 3.29059 2.31938 3.39589 2.33597 3.49858C2.35256 3.60121 2.39857 3.69683 2.46843 3.77385C2.46839 3.77381 2.46847 3.77389 2.46843 3.77385L5.52765 7.13779V10.2692L7.04742 9.05361V7.13779L10.1065 3.77398C10.1065 3.77401 10.1066 3.77394 10.1065 3.77398C10.1764 3.69695 10.2225 3.60122 10.2391 3.49858C10.2557 3.39589 10.2421 3.29059 10.2 3.19546C10.1579 3.10033 10.0891 3.01945 10.0019 2.96265C9.91479 2.90587 9.81303 2.87559 9.709 2.87548H2.86608ZM5.46251 10.3213C5.46264 10.3212 5.46276 10.3211 5.4629 10.321L5.46251 10.3213Z"
        />
      </svg>
    );
  };

  const OnlineIcon = () => {
    return (
      <svg
        className={styles.step_filter_online_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="12"
        viewBox="0 0 22 12"
        fill="none"
      >
        <g filter="url(#filter0_d_227_4132)">
          <rect
            x="2.41748"
            y="0.355469"
            width="17.3265"
            height="7.99686"
            rx="3.99843"
            fill="white"
          />
        </g>
        <circle cx="15.3721" cy="4.354" r="3.33203" fill="#27AE60" />
        <defs>
          <filter
            id="filter0_d_227_4132"
            x="0.978045"
            y="0.355469"
            width="20.2055"
            height="10.8759"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.43944" />
            <feGaussianBlur stdDeviation="0.719718" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_227_4132"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_227_4132"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  const SearchIcon = () => {
    return (
      <svg
        className={styles.step_filter_search_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="#01327C"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.76432 4.8547C2.76432 3.69536 3.70415 2.75553 4.86349 2.75553C6.02283 2.75553 6.96267 3.69536 6.96267 4.8547C6.96267 6.01405 6.02283 6.95388 4.86349 6.95388C3.70415 6.95388 2.76432 6.01405 2.76432 4.8547ZM4.86349 2.15576C3.37291 2.15576 2.16455 3.36412 2.16455 4.8547C2.16455 6.34529 3.37291 7.55365 4.86349 7.55365C5.3979 7.55365 5.89603 7.39833 6.31521 7.13036L7.85019 8.66534C7.9673 8.78246 8.15717 8.78246 8.27428 8.66534C8.3914 8.54823 8.3914 8.35836 8.27428 8.24125L6.78402 6.75098C7.26532 6.26356 7.56243 5.59383 7.56243 4.8547C7.56243 3.36412 6.35407 2.15576 4.86349 2.15576Z"
        />
      </svg>
    );
  };

  const MorePhotosIcon = () => {
    return (
      <svg
        className={styles.step_filter_candidates_more_photos_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
      >
        <g clipPath="url(#clip0_227_4144)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.30029 2.27729C1.30029 1.584 1.89042 1.02197 2.61838 1.02197H7.53924C8.2672 1.02197 8.85733 1.584 8.85733 2.27729V6.96383C8.85733 7.65712 8.2672 8.21915 7.53924 8.21915H2.61838C1.89042 8.21915 1.30029 7.65712 1.30029 6.96383V2.27729ZM2.61838 1.5241C2.1816 1.5241 1.82753 1.86132 1.82753 2.27729V5.72013L2.77513 4.88551C3.01087 4.67787 3.37466 4.67787 3.6104 4.88551L4.49475 5.66442L6.37352 3.98916C6.61576 3.77317 6.99422 3.7791 7.22887 4.00257L8.33009 5.05135V2.27729C8.33009 1.86132 7.97601 1.5241 7.53924 1.5241H2.61838ZM1.82753 6.96383V6.40407L3.1331 5.25416C3.16678 5.22449 3.21875 5.22449 3.25243 5.25416L4.31686 6.19168L4.49695 6.3503L4.67602 6.19063L6.73386 4.35571C6.76847 4.32485 6.82253 4.3257 6.85605 4.35763L8.33009 5.76147V6.96383C8.33009 7.3798 7.97601 7.71702 7.53924 7.71702H2.61838C2.1816 7.71702 1.82753 7.3798 1.82753 6.96383ZM3.23349 3.11417C3.23349 2.79064 3.50888 2.52836 3.84859 2.52836C4.18831 2.52836 4.4637 2.79064 4.4637 3.11417C4.4637 3.43771 4.18831 3.69999 3.84859 3.69999C3.50888 3.69999 3.23349 3.43771 3.23349 3.11417ZM3.84859 2.02623C3.2177 2.02623 2.70625 2.51332 2.70625 3.11417C2.70625 3.71503 3.2177 4.20212 3.84859 4.20212C4.47949 4.20212 4.99094 3.71503 4.99094 3.11417C4.99094 2.51332 4.47949 2.02623 3.84859 2.02623Z"
            fill="#446077"
          />
        </g>
        <defs>
          <clipPath id="clip0_227_4144">
            <rect
              width="8.63661"
              height="8.63661"
              fill="white"
              transform="translate(0.760742 0.301758)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };

  const ChatIcon = () => {
    return (
      <svg
        className={styles.step_filter_candidates_info_button_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="white"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.48869 4.04502C2.48869 3.19826 3.16547 2.51183 4.00032 2.51183H9.58171C10.4166 2.51183 11.0933 3.19826 11.0933 4.04502V6.87553C11.0933 7.72229 10.4166 8.40872 9.58171 8.40872H9.23288V8.76253V9.79536L7.96791 8.51235L7.86574 8.40872H7.72125H4.00032C3.16547 8.40872 2.48869 7.72229 2.48869 6.87553V4.04502ZM4.00032 1.8042C2.78015 1.8042 1.79102 2.80745 1.79102 4.04502V6.87553C1.79102 8.1131 2.78015 9.11635 4.00032 9.11635H7.57676L9.09685 10.6581C9.40451 10.9702 9.93055 10.7492 9.93055 10.3079V9.08857C10.985 8.91894 11.791 7.99271 11.791 6.87553V4.04502C11.791 2.80745 10.8019 1.8042 9.58171 1.8042H4.00032ZM3.65148 4.51677C3.65148 4.32136 3.80766 4.16296 4.00032 4.16296L9.58171 4.16296C9.77437 4.16296 9.93055 4.32137 9.93055 4.51677C9.93055 4.71218 9.77437 4.87058 9.58171 4.87058L4.00032 4.87058C3.80766 4.87058 3.65148 4.71218 3.65148 4.51677ZM3.65148 6.40378C3.65148 6.20837 3.80766 6.04996 4.00032 6.04996H9.58171C9.77437 6.04996 9.93055 6.20837 9.93055 6.40378C9.93055 6.59918 9.77437 6.75759 9.58171 6.75759H4.00032C3.80766 6.75759 3.65148 6.59918 3.65148 6.40378Z"
        />
      </svg>
    );
  };

  const LikeIcon = () => {
    return (
      <svg
        className={styles.step_filter_candidates_info_button_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="#446077"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.19224 2.32856C4.82001 2.09021 4.33515 1.9608 3.67345 2.12136C2.98583 2.28821 2.42966 2.88636 2.19154 3.67201C1.95531 4.45139 2.05323 5.35714 2.60877 6.07593C3.52632 7.26312 5.48392 9.02553 6.56055 9.93893C7.63717 9.02553 9.59477 7.26312 10.5123 6.07593C11.0679 5.35714 11.1658 4.45139 10.9296 3.67201C10.6914 2.88636 10.1353 2.28821 9.44765 2.12136C8.78594 1.9608 8.30109 2.09021 7.92885 2.32856C7.54193 2.5763 7.25192 2.9586 7.03327 3.33519C6.8256 3.69287 6.29549 3.69287 6.08782 3.33519C5.86917 2.9586 5.57916 2.5763 5.19224 2.32856ZM6.33751 10.7326C6.32553 10.7242 6.31402 10.715 6.30307 10.7053C5.30602 9.87508 3.04787 7.87935 2.00229 6.52651C1.28132 5.59368 1.16647 4.43494 1.46212 3.4595C1.75587 2.49032 2.47621 1.64331 3.49064 1.39715C4.35931 1.18637 5.06085 1.35337 5.60832 1.70391C6.01847 1.96653 6.32801 2.32274 6.56055 2.66532C6.79309 2.32274 7.10262 1.96653 7.51277 1.70391C8.06024 1.35337 8.76178 1.18637 9.63046 1.39715C10.6449 1.64331 11.3652 2.49032 11.659 3.4595C11.9546 4.43494 11.8398 5.59368 11.1188 6.52651C10.0732 7.87935 7.81507 9.87508 6.81803 10.7053C6.80707 10.715 6.79556 10.7242 6.78358 10.7326C6.71419 10.7824 6.6365 10.8046 6.56055 10.8042C6.4846 10.8046 6.4069 10.7824 6.33751 10.7326Z"
        />
      </svg>
    );
  };

  const FilterMobileIcon = () => {
    return (
      <svg
        className={styles.step_filter_item_mob_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="white"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68597 2.91406L17.1227 2.91406C17.565 2.91441 17.9982 3.04311 18.3688 3.28456C18.7394 3.52601 19.032 3.86983 19.211 4.2743C19.3899 4.67877 19.4476 5.1265 19.3771 5.56314C19.3065 5.99977 19.1107 6.40653 18.8135 6.73405L13.5933 12.4741V15.4854C13.5932 15.6705 13.5515 15.8535 13.4713 16.0203C13.3911 16.1871 13.2745 16.3337 13.13 16.4494L10.2234 18.7743C10.2233 18.7744 10.2235 18.7742 10.2234 18.7743C10.005 18.9494 9.7332 19.0454 9.45333 19.046C9.26524 19.047 9.07949 19.0043 8.91064 18.9215C8.70278 18.8204 8.52739 18.6631 8.40447 18.4673C8.28099 18.2707 8.21544 18.0432 8.21534 17.811V12.4741L2.99566 6.73453C2.6984 6.40701 2.50216 5.99977 2.4316 5.56314C2.36104 5.1265 2.41875 4.67877 2.59774 4.2743C2.77672 3.86983 3.06926 3.52601 3.43985 3.28456C3.81044 3.04311 4.24366 2.91441 4.68597 2.91406ZM9.45333 19.046C9.45265 19.046 9.45196 19.0461 9.45128 19.0461L9.45077 18.3921L9.45482 19.046C9.45433 19.046 9.45383 19.046 9.45333 19.046ZM4.68625 4.22206C4.49719 4.22226 4.31225 4.27729 4.15387 4.38048C3.99542 4.48371 3.87036 4.6307 3.79386 4.80359C3.71736 4.97648 3.69269 5.16784 3.72285 5.35447C3.75299 5.54099 3.83661 5.71477 3.96357 5.85474C3.9635 5.85467 3.96364 5.85482 3.96357 5.85474L9.52334 11.9683V17.6593L12.2853 15.4501V11.9683L17.8449 5.85498C17.8449 5.85504 17.845 5.85491 17.8449 5.85498C17.9719 5.71499 18.0557 5.54101 18.0858 5.35447C18.116 5.16784 18.0913 4.97648 18.0148 4.80359C17.9383 4.6307 17.8133 4.48371 17.6548 4.38048C17.4964 4.27729 17.3115 4.22226 17.1224 4.22206H4.68625ZM9.40495 17.754C9.40518 17.7538 9.40542 17.7536 9.40566 17.7534L9.40495 17.754Z"
        />
      </svg>
    );
  };

  const BellMobileIcon = () => {
    return (
      <svg
        className={styles.step_filter_mob_bell_icon}
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="#446077"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7801 4.87839C15.8951 4.8957 16.0131 4.91433 16.1347 4.93425L16.1858 4.94263L16.2354 4.95723C19.4591 5.90659 21.8142 8.81243 21.8142 12.2458V13.6938C21.8142 16.1934 22.7965 18.561 24.5822 20.3651C24.9964 20.7837 25.113 21.4015 24.8781 21.935C24.6432 22.4684 24.1024 22.8146 23.5038 22.8146H4.40145C3.80284 22.8146 3.26197 22.4685 3.02709 21.935C2.79221 21.4015 2.90867 20.7837 3.32302 20.3651C5.10871 18.561 6.09104 16.1934 6.09104 13.6938V12.2458C6.09104 8.81239 8.44605 5.90659 11.6698 4.95723L11.7117 4.9449L11.7547 4.93701C11.8488 4.91971 11.9408 4.90348 12.031 4.88828C12.3854 3.73023 13.0932 3.24219 13.9071 3.24219C14.7186 3.24219 15.4247 3.72745 15.7801 4.87839ZM13.9531 25.3329C15.349 25.3329 16.5511 24.516 17.0785 23.35H10.8276C11.3551 24.516 12.5572 25.3329 13.9531 25.3329ZM7.71486 12.2458C7.71486 9.54044 9.56156 7.24118 12.1011 6.47465C13.5111 6.21892 14.3181 6.23478 15.8115 6.47688C18.3472 7.24563 20.1904 9.5431 20.1904 12.2458V13.6938C20.1904 16.5021 21.2593 19.1723 23.2051 21.2414H4.70015C6.6459 19.1723 7.71486 16.5021 7.71486 13.6938V12.2458ZM19.5088 4.73198C19.7313 4.35479 20.2272 4.22375 20.6166 4.43928C21.9959 5.2029 24.4216 7.74782 24.4216 12.1725C24.4216 12.6069 24.0581 12.9591 23.6097 12.9591C23.1613 12.9591 22.7978 12.6069 22.7978 12.1725C22.7978 8.32484 20.6956 6.29496 19.8109 5.8052C19.4216 5.58967 19.2863 5.10917 19.5088 4.73198ZM7.19777 4.43928C7.58709 4.22375 8.08305 4.35479 8.30552 4.73198C8.528 5.10917 8.39273 5.58967 8.00341 5.8052C7.11875 6.29496 5.01653 8.32484 5.01653 12.1725C5.01653 12.6069 4.65303 12.9591 4.20462 12.9591C3.75622 12.9591 3.39271 12.6069 3.39271 12.1725C3.39271 7.74782 5.81845 5.2029 7.19777 4.43928Z"
        />
      </svg>
    );
  };

  const LogoSize = () => {
    return;
  };

  return (
    <>
      <div className={styles.tutorial_step_illustration}>
        <div className={styles.step_filter}>
          <div className={styles.step_filter_header}>
            <div className={styles.step_filter_age_range}>
              <p className={styles.step_filter_age_text}>Age range</p>
              <div className={styles.step_filter_inputs}>
                <div className={styles.step_filter_item}>
                  <p className={styles.step_filter_item_text}>From</p>
                  <ArrowDownIcon />
                </div>
                <div className={styles.step_filter_item}>
                  <p className={styles.step_filter_item_text}>To</p>
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
            <div className={styles.step_filter_item_detailed}>
              <FilterDetailedIcon />
              <p className={styles.step_filter_item_detailed_text}>
                Detailed filter
              </p>
              <ArrowDownIcon />
            </div>
            <div className={styles.step_filter_online}>
              <p className={styles.step_filter_online_text}>online</p>
              <OnlineIcon />
            </div>
            <div className={styles.step_filter_search}>
              <div className={styles.step_filter_text}>Search ID or na</div>
              <SearchIcon />
            </div>
          </div>
          <div className={styles.step_filter_mob_header}>
            <div className={styles.step_filter_item_mob}>
              <FilterMobileIcon />
              <p className={styles.step_filter_item_mob_text}>Filters</p>
            </div>
            <div
              className={`${styles.step_filter_mob_logo_wrap} ${
                styles[`${LogoSize()}`]
              }`}
            >
              <Image
                name={MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_LOGO}
                size={"10px"}
                className={styles.step_filter_mob_logo_icon}
              />
            </div>

            <div className={styles.step_filter_mob_bell}>
              <BellMobileIcon />
            </div>
          </div>
          <div className={styles.step_filter_candidates}>
            <div className={styles.step_filter_candidates_card}>
              <div className={styles.step_filter_candidates_profile}>
                {!+userState?.gender ? (
                  <img
                    src={tut_step_3_girl_1}
                    alt="Candidate"
                    width="147"
                    height="211"
                    className={styles.step_filter_candidates_photo}
                  />
                ) : (
                  <img
                    src={tut_step_3_man_1}
                    alt="Candidate"
                    width="147"
                    height="211"
                    className={styles.step_filter_candidates_photo}
                  />
                )}
                <div className={styles.step_filter_candidates_more_photos}>
                  <MorePhotosIcon />
                  <p className={styles.step_filter_candidates_more_photos_text}>
                    All photo
                  </p>
                </div>
              </div>
              <div className={styles.step_filter_candidates_online}>
                <p className={styles.step_filter_candidates_online_text}>
                  I’m online now
                </p>
              </div>
              <div className={styles.step_filter_candidates_info}>
                <div className={styles.step_filter_candidates_info_wrap}>
                  <div className={styles.step_filter_candidates_info_buttons}>
                    <div
                      className={styles.step_filter_candidates_info_button_chat}
                    >
                      <ChatIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_chat_text
                        }
                      >
                        Chat now
                      </p>
                    </div>
                    <div
                      className={styles.step_filter_candidates_info_button_like}
                    >
                      <LikeIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_like_text
                        }
                      >
                        Like
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.step_filter_candidates_card}>
              <div className={styles.step_filter_candidates_profile}>
                {!+userState?.gender ? (
                  <img
                    src={tut_step_3_girl_2}
                    alt="Candidate"
                    width="147"
                    height="211"
                    className={styles.step_filter_candidates_photo}
                  />
                ) : (
                  <img
                    src={tut_step_3_man_2}
                    alt="Candidate"
                    width="147"
                    height="211"
                    className={styles.step_filter_candidates_photo}
                  />
                )}
                <div className={styles.step_filter_candidates_more_photos}>
                  <MorePhotosIcon />
                  <p className={styles.step_filter_candidates_more_photos_text}>
                    All photo
                  </p>
                </div>
              </div>
              <div className={styles.step_filter_candidates_online}>
                <p className={styles.step_filter_candidates_online_text}>
                  I’m online now
                </p>
              </div>
              <div className={styles.step_filter_candidates_info}>
                <div className={styles.step_filter_candidates_info_wrap}>
                  <div className={styles.step_filter_candidates_info_buttons}>
                    <div
                      className={styles.step_filter_candidates_info_button_chat}
                    >
                      <ChatIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_chat_text
                        }
                      >
                        Chat now
                      </p>
                    </div>
                    <div
                      className={styles.step_filter_candidates_info_button_like}
                    >
                      <LikeIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_like_text
                        }
                      >
                        Like
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.step_filter_candidates_card} ${styles.mobile}`}
            >
              <div className={styles.step_filter_candidates_profile}>
                {!+userState?.gender ? (
                  <Image name="tut_step_3_girl_3" />
                ) : (
                  <Image name="tut_step_3_man_3" />
                )}
                <div className={styles.step_filter_candidates_more_photos}>
                  <MorePhotosIcon />
                  <p className={styles.step_filter_candidates_more_photos_text}>
                    All photo
                  </p>
                </div>
              </div>
              <div className={styles.step_filter_candidates_online}>
                <p className={styles.step_filter_candidates_online_text}>
                  I’m online now
                </p>
              </div>
              <div className={styles.step_filter_candidates_info}>
                <div className={styles.step_filter_candidates_info_wrap}>
                  <div className={styles.step_filter_candidates_info_buttons}>
                    <div
                      className={styles.step_filter_candidates_info_button_chat}
                    >
                      <ChatIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_chat_text
                        }
                      >
                        Chat now
                      </p>
                    </div>
                    <div
                      className={styles.step_filter_candidates_info_button_like}
                    >
                      <LikeIcon />
                      <p
                        className={
                          styles.step_filter_candidates_info_button_like_text
                        }
                      >
                        Like
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.step_filter_footer}>
            <p className={styles.step_filter_footer_text}>
              Use filters to choose your preferences
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
