import { useEffect } from "react";
import classes from "./GoogleAuthButton.module.scss";
import jwt_decode from "jwt-decode";
import { MIRROR } from "data/mirrorConfig";

const GoogleAuthButton = ({ responseGoogle }) => {
  useEffect(() => {
    if (
      window.google &&
      MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_GOOGLE_ID
    ) {
      const handleResponse = async (response) => {
        const userData = jwt_decode(response?.credential);

        responseGoogle(userData);
      };

      window.google.accounts.id.initialize({
        client_id:
          MIRROR[process.env.REACT_APP_MIRROR_NAME].REACT_APP_GOOGLE_ID,
        callback: handleResponse,
      });

      const googleSignInButton = document.getElementById(
        "google-sign-in-button"
      );
      window.google.accounts.id.renderButton(googleSignInButton, {
        type: "icon",
        theme: "outline",
        size: "large",
        locale: "en",
      });

      window.google.accounts.id.prompt();
    }
  }, [window?.google]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id="google-sign-in-button" className={classes.new_google_btn} />;
};

export default GoogleAuthButton;
