import { TooltipTransaction } from "components/atomsKit/Elements";

import { useNavigate } from "react-router-dom";

import { userIcon } from "img/userLogo";

import { transactionContentConfig } from "data/config";

// @ts-ignore
import classes from "./Tooltips.module.scss";

interface IProps {
  transactions?: any;
  handleDelTransaction: (id: any) => void;
  handleDelNotification: (id: any) => void;
  handleTransaction: (chat_uid: any, id: any, isMail?: boolean) => void;
  userInfo?: any;
}

const Tooltips = ({
  transactions,

  handleTransaction,
  handleDelNotification,
  userInfo,
}: IProps) => {
  const navigate = useNavigate();
  return (
    <div className={classes.wrap}>
      {transactions.reverse().map((item: any) => (
        <TooltipTransaction
          id={item?.message?.id || item?.id}
          message={
            item?.notification_object?.message_type === "SENT_TEXT"
              ? item?.notification_object?.message_content
              : transactionContentConfig(
                  item?.action,
                  item?.notification_object?.name
                )?.text
          }
          name={item?.notification_object?.name}
          age={item?.notification_object?.age}
          imgLink={
            item?.notification_object?.photo_link ||
            item?.notification_object?.avatar
          }
          imgLinkUser={
            transactionContentConfig(
              item?.action,
              item?.notification_object?.name
            )?.isDoubleShow
              ? userInfo?.photo_link || userIcon
              : undefined
          }
          iconType={
            transactionContentConfig(
              item?.action,
              item?.notification_object?.name
            )?.icon
          }
          bgColor={
            transactionContentConfig(
              item?.action,
              item?.notification_object?.name
            )?.bgColor
          }
          handleDel={() => {
            handleDelNotification(item?.idTransaction);
          }}
          handleCard={
            item?.action === "message"
              ? () => {
                  handleTransaction(
                    item?.notification_object?.chat_uid,
                    item?.external_id
                  );
                }
              : item?.action === "mail"
              ? () => {
                  handleTransaction(
                    item?.notification_object?.chat_uid,
                    item?.notification_object?.public_external_id,
                    true
                  );
                }
              : () => {
                  navigate(
                    `/profile-woman/${item?.notification_object?.sender_external_id}`
                  );
                }
          }
        />
      ))}
    </div>
  );
};

export default Tooltips;
