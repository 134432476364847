const prefix =
  process.env.REACT_APP_API_MIRROR !== "Production" ? "mstage." : "m.";
const payPrefix =
  process.env.REACT_APP_API_MIRROR !== "Production" ? "stage." : "";

const MIRROR = {
  sofiadate: {
    REACT_APP_API_MIRROR: process.env.REACT_APP_API_MIRROR,
    REACT_APP_API_URL: `${prefix}sofiadate.com`,
    REACT_APP_PAY_URL: `${payPrefix}sofiadate.com`,
    REACT_APP_NAME: "SofiaDate",
    REACT_APP_SUPPORT_MAIL: "support@sofiadate.com",
    REACT_APP_GOOGLE_ID:
      "539249899214-o2vvebvr6d29as2nk6ap087ln2698704.apps.googleusercontent.com",
    REACT_APP_SITE_ID: 1,
    REACT_APP_BRAND_URL: "sofia20",
    REACT_APP_COLORS: "colors_sofiadate.scss",
    REACT_APP_LOGO: "logo_light_sofiadate",
    REACT_APP_LOGO_LOGIN: "logo_sofia",
    REACT_APP_LOGO_VIP: "logo_vip_light_sofiadate",
    REACT_APP_COLOR_LOADER: "#00317B",
  },
  avodate: {
    REACT_APP_API_MIRROR: process.env.REACT_APP_API_MIRROR,
    REACT_APP_API_URL: `${prefix}avodate.com`,
    REACT_APP_PAY_URL: `${payPrefix}avodate.com`,
    REACT_APP_NAME: "AvoDate",
    REACT_APP_SUPPORT_MAIL: "support@avodate.com",
    REACT_APP_GOOGLE_ID:
      "539249899214-cnt6a46a7mmj5h60c7cv78ruv2m8rvu6.apps.googleusercontent.com",
    REACT_APP_SITE_ID: 8,
    REACT_APP_BRAND_URL: "avodate",
    REACT_APP_COLORS: "colors_avo.scss",
    REACT_APP_LOGO: "logo-light-avodate",
    REACT_APP_LOGO_LOGIN: "logoAVO",
    REACT_APP_LOGO_VIP: "site_logo_vip_mob_avo",
    REACT_APP_COLOR_LOADER: "#289292",
  },
};

module.exports = { MIRROR };
