import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";

import { selectUser } from "redux/auth/selectors";

import {
  updateStatusOnlineGirlList,
  updateStatusOnlineProfileCard,
} from "redux/girlsList/slice";
import {
  togleReFetch,
  addTransaction,
  delTransaction,
  addNotification,
  delNotification,
} from "redux/interface/slice";
import {
  readStatus,
  updateStatusOnlineChatList,
  updateStatusOnlineMessager,
} from "redux/chat/slice";
import {
  updateStatusOnlineLetterList,
  updateStatusOnlineLetterProfile,
} from "redux/letters/slice";

import { chatCounter } from "redux/interface/operations";

import useMakeNotificationSound from "hooks/useMakeNotificationSound";

import { initSocket, getSocket } from "utils/socket";

import { postActionTransaction } from "redux/interface/operations";
import { logOut } from "redux/auth/operations";

import { getDifferenceInSeconds } from "utils/common";

import { useAuth } from "hooks";
// import * as SYNC from "data/sync";

const SocketProvider = () => {
  const { isLoggedIn } = useAuth();
  const userInfo = useSelector(selectUser);
  const userExternalId = useSelector(selectUser)?.user_info?.external_id;

  const dispatch = useDispatch();

  const { playSound } = useMakeNotificationSound();

  useEffect(() => {
    if ((isLoggedIn, userInfo?.user_info?.external_id)) {
      initSocket();
      // @ts-ignore
      getSocket().on(
        `counters_profile_${userInfo?.user_info?.external_id}`,
        (event) => {
          actionSocket(event);
        }
      );
      getSocket().on(`woman_info_channel_0`, (event) => {
        actionSocket(event);
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn, userInfo?.user_info?.external_id]);

  const actionSocket = (event) => {
    const { action } = event;
    dispatch(togleReFetch(event));

    switch (action) {
      case "FRONT_SYNC_TRANSACTION":
        postActionTransaction(event?.message);
        break;
      case "NEW_GIRL":
      case "VIEW_PROFILE":
      case "MAYBE_APPEAL":
      case "POSSIBLE_MATCH":
      case "NO_CHAT":
      case "MAYBE_CHAT":
      case "STRANGER":
      case "SEARCH_PARTNER":
      case "PROFILE_UPDATE":
      case "ONLINE_NOW":
      case "MATCH":
        if (event?.notification_object) {
          playSound();
          const idTransaction = nanoid(5);
          dispatch(addTransaction({ ...event, idTransaction: idTransaction }));
          setTimeout(() => {
            dispatch(delTransaction(idTransaction));
          }, getDifferenceInSeconds(event?.notification_object?.date_created, event?.notification_object?.expired_at));
        }
        break;
      case "open_chat":
        dispatch(readStatus(event));
        break;

      case "user_logout":
        dispatch(logOut());
        break;

      case "message":
      case "liked":
      case "mail":
        playSound();
        dispatch(chatCounter(userExternalId));
        const idTransaction = nanoid(5);
        dispatch(addNotification({ ...event, idTransaction: idTransaction }));
        setTimeout(() => {
          dispatch(delNotification(idTransaction));
        }, getDifferenceInSeconds(event?.notification_object?.date_created, event?.notification_object?.date_expired || event?.notification_object?.expired_at));
        break;
      case "online_status":
        dispatch(updateStatusOnlineGirlList(event));
        dispatch(updateStatusOnlineProfileCard(event));
        dispatch(updateStatusOnlineChatList(event));
        dispatch(updateStatusOnlineMessager(event));
        dispatch(updateStatusOnlineLetterList(event));
        dispatch(updateStatusOnlineLetterProfile(event));
        break;

      default:
        break;
    }
  };

  return <div />;
};

export default SocketProvider;
